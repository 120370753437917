<template>
  <div :class="!isDesktop ? 'request-tab__item' : 'aside-card'">
    <div :class="!isDesktop ? 'request-tab__header' : 'aside-card__header'" @click="changeShowStatus">
      <span :class="!isDesktop ? 'request-tab__title' : 'aside-card__id'">Водитель</span>
      <ChevronUpIcon v-if="showStatus === 'MOBILE'" />
      <ChevronDownIcon v-if="showStatus === 'HIDDEN'" />
    </div>
    <div class="aside-card__body" :class="{ 'aside-card': !isDesktop }" v-if="showStatus !== 'HIDDEN'">
      <div class="aside-card__item">
        <span class="aside-card__item-title">ФИО</span>
        <span class="aside-card__item-value">
          <template v-for="r in pass.requests">
            <template v-if="r.delivery && r.delivery.delivery_driver">
              {{ r.delivery.delivery_driver | getFIO }}
            </template>
            <template v-else>-</template>
          </template>
        </span>
      </div>
      <div class="aside-card__item">
        <span class="aside-card__item-title">Тип документа</span>
        <span class="aside-card__item-value">
          <template v-for="r in pass.requests">
            <template v-if="r.delivery && r.delivery.delivery_driver && r.delivery.delivery_driver.type">
              {{ r.delivery.delivery_driver.type.name }}
            </template>
            <template v-else>-</template>
          </template>
        </span>
      </div>
      <div class="aside-card__item">
        <span class="aside-card__item-title">Номер документа</span>
        <span class="aside-card__item-value">
          <template v-for="r in pass.requests">
            <template v-if="r.delivery && r.delivery.delivery_driver && r.delivery.delivery_driver.type">
              <template v-if="r.delivery.delivery_driver.type.code === 1">
                {{ r.delivery.delivery_driver.passport_number }}
              </template>
              <template v-if="r.delivery.delivery_driver.type.code === 2">
                {{ r.delivery.delivery_driver.license_number }}
              </template>
            </template>
            <template v-else>-</template>
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronUpIcon from "@/components/svg/ChevronUpIcon.vue";
import ChevronDownIcon from "@/components/svg/ChevronDownIcon.vue";

export default {
  name: "PassDriverInfo",
  components: { ChevronDownIcon, ChevronUpIcon },
  props: {
    pass: Object,
  },
  data() {
    return {
      isDesktop: window.innerWidth > 768,
      showStatus: window.innerWidth > 768 ? "DESKTOP" : "HIDDEN",
    };
  },
  methods: {
    changeShowStatus() {
      if (window.innerWidth <= 768) {
        if (this.showStatus === "MOBILE") {
          this.showStatus = "HIDDEN";
        } else {
          this.showStatus = "MOBILE";
        }
      } else {
        this.showStatus = "DESKTOP";
      }
    },
  },
};
</script>
