<template>
  <div :class="!isDesktop ? 'request-tab__item' : 'aside-card'">
    <div :class="!isDesktop ? 'request-tab__header' : 'aside-card__header'" @click="changeShowStatus">
      <span :class="!isDesktop ? 'request-tab__title' : 'aside-card__id'">Автомобиль</span>
      <ChevronUpIcon v-if="showStatus === 'MOBILE'" />
      <ChevronDownIcon v-if="showStatus === 'HIDDEN'" />
    </div>
    <div class="aside-card__body" :class="{ 'aside-card': !isDesktop }" v-if="showStatus !== 'HIDDEN'">
      <div class="aside-card__item">
        <span class="aside-card__item-title">Марка</span>
        <span class="aside-card__item-value">
          <template v-for="r in pass.requests">
            {{ r.delivery && r.delivery.car && r.delivery.car.mark ? r.delivery.car.mark.title : " - " }}
          </template>
        </span>
      </div>
      <div class="aside-card__item">
        <span class="aside-card__item-title">Гос. номер</span>
        <span class="aside-card__item-value">
          <template v-for="r in pass.requests">
            {{ r.delivery && r.delivery.car ? r.delivery.car.state_number : " - " }}
          </template>
        </span>
      </div>
      <div class="aside-card__item">
        <span class="aside-card__item-title">Номер документа</span>
        <span class="aside-card__item-value">
          <template v-for="r in pass.requests">
            {{ r.delivery && r.delivery.car ? r.delivery.car.registration_number : " - " }}
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronUpIcon from "@/components/svg/ChevronUpIcon.vue";
import ChevronDownIcon from "@/components/svg/ChevronDownIcon.vue";

export default {
  name: "PassCarInfo",
  components: { ChevronDownIcon, ChevronUpIcon },
  data() {
    return {
      isDesktop: window.innerWidth > 768,
      showStatus: window.innerWidth > 768 ? "DESKTOP" : "HIDDEN",
    };
  },
  methods: {
    changeShowStatus() {
      if (window.innerWidth <= 768) {
        if (this.showStatus === "MOBILE") {
          this.showStatus = "HIDDEN";
        } else {
          this.showStatus = "MOBILE";
        }
      } else {
        this.showStatus = "DESKTOP";
      }
    },
  },
  props: {
    pass: Object,
  },
};
</script>
