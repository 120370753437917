<template>
  <div :class="!isDesktop ? 'request-tab__item' : 'aside-card'" v-if="pass">
    <div :class="!isDesktop ? 'request-tab__header' : 'aside-card__header'" @click="changeShowStatus">
      <span :class="!isDesktop ? 'request-tab__title' : 'aside-card__id'">Общая информация</span>
      <ChevronUpIcon v-if="showStatus === 'MOBILE'" />
      <ChevronDownIcon v-if="showStatus === 'HIDDEN'" />
    </div>
    <div
      class="aside-card__body"
      :class="{ 'aside-card': !isDesktop }"
      v-if="showStatus !== 'HIDDEN' && pass.requests && pass.requests.length"
    >
      <div class="aside-card__item">
        <span class="aside-card__item-title">Место доставки</span>
        <span class="aside-card__item-value">
          <template v-for="r in pass.requests.filter((r) => r.delivery)">
            {{ r.delivery.warehouse ? r.delivery.warehouse.title : "" }}
          </template>
        </span>
      </div>
      <div class="aside-card__item">
        <span class="aside-card__item-title">Поставщик</span>
        <span class="aside-card__item-value">
          <template v-for="r in pass.requests.filter((r) => r.provider)">
            {{ r.provider | getCompany }}
          </template>
        </span>
      </div>
      <div class="aside-card__item">
        <span class="aside-card__item-title">Дата доставки</span>
        <span class="aside-card__item-value">
          <template v-for="r in pass.requests.filter((r) => r.delivery_date)">
            {{ r.delivery_date | formatDate }}
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronUpIcon from "@/components/svg/ChevronUpIcon.vue";
import ChevronDownIcon from "@/components/svg/ChevronDownIcon.vue";

export default {
  name: "PassGeneralInfo",
  props: {
    pass: Object,
  },
  components: { ChevronDownIcon, ChevronUpIcon },
  data() {
    return {
      isDesktop: window.innerWidth > 768,
      showStatus: window.innerWidth > 768 ? "DESKTOP" : "HIDDEN",
    };
  },
  methods: {
    changeShowStatus() {
      if (window.innerWidth <= 768) {
        if (this.showStatus === "MOBILE") {
          this.showStatus = "HIDDEN";
        } else {
          this.showStatus = "MOBILE";
        }
      } else {
        this.showStatus = "DESKTOP";
      }
    },
  },
};
</script>
