<template>
  <aside class="aside" v-if="pass">
    <div class="aside-header">
      <h2 class="request-tab__title">Статус</h2>
      <span :class="getStatus(pass)">
        {{ pass.status.name }}
      </span>
    </div>
    <div class="aside-card" v-if="pass.journal.length">
      <div class="aside-card__header">
        <span class="aside-card__id">История</span>
      </div>
      <div class="aside-card__body">
        <div class="aside-card__item" v-for="(j, i) in pass.journal" :key="i">
          <span class="aside-card__item-title">
            <span class="date">
              <span class="date__main">{{ j.created_at | formatDate }}</span>
              <span class="date__sub">{{ j.created_at | formatTime }}</span>
            </span>
          </span>
          <span class="aside-card__item-value">
            <span :class="getStatus(j)">
              {{ j.status.name }}
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="aside-header">
      <h2 class="request-tab__title">Код пропуска</h2>
    </div>
    <div class="aside-card" v-if="pass.qr_code">
      <div class="aside-card__header">
        <span class="aside-card__id">Код на въезд</span>
      </div>
      <div class="aside-card__body">
        <div class="aside-card__item-qr_code">
          <img :src="pass.qr_code" alt="" />
          <a class="btn" target="_self" download :href="pass.qr_code">Скачать QR-код</a>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  name: "PassAside",
  props: {
    pass: Object,
  },
  methods: {
    getStatus(row) {
      let string = "status";
      switch (row.status.code) {
        case 1:
          break;
        case 6:
          string += " status--red";
          break;
        case 2:
          string += " status--orange";
          break;
        case 3:
        case 4:
        case 5:
          string += " status--green";
          break;
      }
      return string;
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/aside"
</style>
