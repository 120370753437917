<template>
  <div class="request-tab">
    <PassInfoSection :pass="pass" />
    <PassCargo :pass="pass" />
    <PassCargoDocuments :pass="pass" />
    <PassOtherCargoDocuments :pass="pass" />
  </div>
</template>

<script>
import PassInfoSection from "./sections/info.vue";
import PassCargo from "./sections/cargo.vue";
import PassCargoDocuments from "./sections/documents.vue";
import PassOtherCargoDocuments from "./sections/otherCargo.vue";
export default {
  name: "PassSections",
  components: { PassOtherCargoDocuments, PassCargoDocuments, PassCargo, PassInfoSection },
  props: {
    pass: Object,
  },
};
</script>
