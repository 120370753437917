<template>
  <div class="page-header">
    <div class="page-header__title">
      <a @click.prevent="$router.go(-1)" href="#" type="button" class="page__back">
        <ArrowLeftIcon />
        <span>Вернуться назад</span>
      </a>
      <h1 class="page__title">Пропуск #-{{ $route.params.id }}</h1>
    </div>
    <div class="page-header__actions" v-if="pass">
      <button
        class="btn btn--icon-left"
        :class="{
          'btn--loading': acceptLoading,
        }"
        type="button"
        @click="accept"
        v-if="pass.can_accept"
      >
        <LoaderIcon v-if="acceptLoading" class="loading-rotate" />
        <CheckSymbolIcon v-else />
        <span v-if="pass.status.code === 2">Прибыл</span>
        <span v-else-if="pass.status.code === 3">Выехал</span>
        <span v-else-if="pass.status.code === 4">Выехал с территории</span>
        <span v-else>Принять</span>
      </button>
      <button
        class="btn-red btn--icon-left"
        :class="{
          'btn-red--outline': !declineLoading,
          'btn-red--loading': declineLoading,
        }"
        @click="decline"
        type="button"
        v-if="pass.can_deny"
      >
        <LoaderIcon v-if="declineLoading" class="loading-rotate" />
        <CloseIcon v-else />
        <span>Отклонить</span>
      </button>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@/components/svg/CloseIcon.vue";
import CheckSymbolIcon from "@/components/svg/CheckSymbolIcon.vue";
import LoaderIcon from "@/components/svg/LoaderIcon.vue";
import ArrowLeftIcon from "@/components/svg/ArrowLeftIcon.vue";
import DeclinePassModal from "./declineModal.vue";

export default {
  name: "PassHeaderComponent",
  props: {
    pass: Object,
  },
  data() {
    return {
      saveSuccessMessage: "Изменения успешно сохранены",
      acceptLoading: false,
      declineLoading: false,
    };
  },
  methods: {
    accept() {
      if (!this.acceptLoading) {
        this.acceptLoading = true;
        this.$store
          .dispatch("ACCEPT_PASS", {
            context: this,
            variables: {
              access_id: this.pass.id,
            },
          })
          .then(({ data }) => {
            this.acceptLoading = false;
            if (data.AcceptAccess) {
              this.$notify({
                title: "Успешно",
                text: data.AcceptAccess.message,
                type: "success",
              });
              this.$router.push({ name: "Passes" });
            }
          });
      }
    },
    declineAccept(data) {
      if (!this.declineLoading) {
        this.declineLoading = true;
        data.access_id = this.pass.id;
        data.reason_ids = data.reason_ids.value.map((r) => r.id);
        data.message = data.message.value;
        this.$store
          .dispatch("DENY_PASS", {
            context: this,
            variables: data,
          })
          .then(({ data }) => {
            this.declineLoading = false;
            if (data.DenyAccess) {
              this.$notify({
                title: "Успешно",
                text: data.DenyAccess.message,
                type: "success",
              });
              this.$router.push({ name: "Passes" });
            }
          });
      }
    },
    decline() {
      this.$store.state._modals.push({
        component: DeclinePassModal,
        options: {
          callback: this.declineAccept,
        },
      });
    },
  },
  components: {
    ArrowLeftIcon,
    LoaderIcon,
    CheckSymbolIcon,
    CloseIcon,
  },
};
</script>
