<template>
  <div class="request-tab__item">
    <div class="request-tab__header" @click="changeShowStatus">
      <h2 class="request-tab__title">Груз</h2>
      <ChevronUpIcon v-if="showStatus === 'MOBILE'" />
      <ChevronDownIcon v-if="showStatus === 'HIDDEN'" />
    </div>
    <Table :columns="columns" :rows="cargo" listClass="cargo-card-list" v-if="showStatus !== 'HIDDEN'">
      <div slot="empty" class="td-container">
        <span class="table-span">
          <template>Данных нет</template>
        </span>
      </div>
      <template slot="table-row" slot-scope="{ row, column }">
        <div class="td-container" v-if="column.field === 'count'">
          <label class="table-count-input">
            <span>0 / {{ row.QtyFact || row.QtyPlan }}</span>
          </label>
        </div>
        <div class="td-container" v-else-if="column.field === 'unit'">
          <span class="table-span">
            {{ row.ElFact || row.ElPlan }}
          </span>
        </div>
        <div class="td-container" v-else-if="column.field === 'actions'">
          <div class="table-actions">
            <router-link :to="{ name: 'Request', params: { id: row.id } }" class="table-action">
              <EyeOnIcon />
            </router-link>
          </div>
        </div>
        <div class="td-container" v-else>
          <span class="table-span">
            <b>{{ row[column.field] || "" }}</b>
          </span>
        </div>
      </template>
      <div slot="table-m-item" slot-scope="{ row }" class="cargo-card">
        <div class="cargo-card__header">
          <span class="cargo-card__item-title">Наименование</span>
          <span class="cargo-card__id">{{ row.String }}</span>
        </div>
        <div class="cargo-card__body">
          <div class="cargo-card__item">
            <span class="cargo-card__item-title">Ед. измерения</span>
            <span class="cargo-card__item-value">{{ row.ElFact || row.ElPlan }}</span>
          </div>
          <div class="cargo-card__item">
            <span class="cargo-card__item-title">Количество</span>
            <span class="cargo-card__item-value">
              <label class="table-count-input">
                <span>0 / {{ row.QtyFact || row.QtyPlan }}</span>
              </label>
            </span>
          </div>
        </div>
      </div>
    </Table>
  </div>
</template>

<script>
import Table from "@/components/Table";
import EyeOnIcon from "@/components/svg/EyeOnIcon";
import ChevronDownIcon from "@/components/svg/ChevronDownIcon";
import ChevronUpIcon from "@/components/svg/ChevronUpIcon";

export default {
  name: "PassCargo",
  props: {
    pass: Object,
  },
  data() {
    return {
      showStatus: window.innerWidth > 768 ? "DESKTOP" : "HIDDEN",
      cargoItems: [],
      columns: [
        {
          label: "Наименование",
          field: "title",
          width: "100%",
          sortable: true,
        },
        {
          label: "Количество",
          field: "count",
          width: "150px",
          sortable: true,
        },
        {
          label: "Ед. измерения",
          field: "unit",
          width: "200px",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    cargo() {
      let contracts = this.pass.requests.map((r) => r.contracts).flat();
      return [...new Set(contracts.map((c) => c.cargo).flat())];
    },
  },
  methods: {
    changeShowStatus() {
      if (window.innerWidth <= 768) {
        if (this.showStatus === "MOBILE") {
          this.showStatus = "HIDDEN";
        } else {
          this.showStatus = "MOBILE";
        }
      } else {
        this.showStatus = "DESKTOP";
      }
    },
  },
  components: { ChevronUpIcon, ChevronDownIcon, EyeOnIcon, Table },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/cargo-card.styl"
</style>
