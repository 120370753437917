<template>
  <main class="page request-page" :class="{ 'request-create-page': !$route.params.id }">
    <PassHeaderComponent :pass="OneAccess" />
    <div class="page__container">
      <PassAside :pass="OneAccess" />
      <div class="page__content" v-if="!loading">
        <PassSections :pass="OneAccess" />
      </div>
      <div class="page__content" v-else>
        <LoadingIndicator title="Загрузка" />
      </div>
    </div>
  </main>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import PassHeaderComponent from "./components/header.vue";
import PassAside from "./components/aside.vue";
import PassSections from "./components/openSections.vue";
export default {
  name: "PassesOpen",
  data() {
    return {
      OneAccess: null,
      loading: false,
      isScannerQR: false,
    };
  },
  created() {
    this.load();
  },
  watch: {
    "$route.params.id"() {
      this.load();
    },
  },
  methods: {
    load() {
      if (!this.loading) {
        this.loading = true;
        this.$store
          .dispatch("GET_PASS", {
            context: this,
            variables: {
              id: parseInt(this.$route.params.id),
            },
          })
          .then(({ data }) => {
            this.loading = false;
            this.OneAccess = data.OneAccess;
          });
      }
    },
  },
  components: { PassSections, PassAside, PassHeaderComponent, LoadingIndicator },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/request"
</style>
