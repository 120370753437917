<template>
  <div class="request-tab__item">
    <div class="request-tab__header" v-if="showHeader">
      <h2 class="request-tab__title">Информация</h2>
    </div>
    <div class="info-section__items">
      <PassStatusInfo :pass="pass" v-if="showStatus" />
      <PassGeneralInfo :pass="pass" />
      <PassCarInfo :pass="pass" />
      <PassDriverInfo :pass="pass" />
    </div>
  </div>
</template>
<script>
import PassGeneralInfo from "./info/general.vue";
import PassDriverInfo from "./info/driver.vue";
import PassCarInfo from "./info/car.vue";
import PassStatusInfo from "./info/status.vue";

export default {
  name: "PassInfoSection",
  components: { PassStatusInfo, PassCarInfo, PassDriverInfo, PassGeneralInfo },
  props: {
    pass: Object,
  },
  data() {
    return {
      showStatus: window.innerWidth <= 1240,
      showHeader: window.innerWidth > 768,
    };
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/aside"
.info-section {
  &__items {
    display grid
    width 100%
    grid-gap 32px
    grid-template-columns 1fr 1fr 1fr
    +below(1540px) {
      grid-template-columns 1fr 1fr
    }
    +below(768px) {
      grid-gap 24px
      grid-template-columns 1fr
    }
  }
}
</style>
