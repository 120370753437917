<template>
  <div :class="!isDesktop ? 'request-tab__item' : 'aside-card'">
    <div :class="!isDesktop ? 'request-tab__header' : 'aside-card__header'" @click="changeShowStatus">
      <span :class="!isDesktop ? 'request-tab__title' : 'aside-card__id'">Статус</span>
      <ChevronUpIcon v-if="showStatus === 'MOBILE'" />
      <ChevronDownIcon v-if="showStatus === 'HIDDEN'" />
    </div>
    <div class="aside-card__body" :class="{ 'aside-card': !isDesktop }" v-if="showStatus !== 'HIDDEN'">
      <div class="aside-card__item" v-for="(j, i) in pass.journal" :key="i">
        <span class="aside-card__item-title">
          <template v-if="i === 0">Текущий</template>
          <span class="date" v-else>
            <span class="date__main">{{ j.created_at | formatDate }}</span>
            <span class="date__sub">{{ j.created_at | formatTime }}</span>
          </span>
        </span>
        <span class="aside-card__item-value">
          <span :class="getStatus(j)">
            {{ j.status.name }}
          </span>
        </span>
      </div>
      <div class="aside-header">
        <h2 class="request-tab__title">Код пропуска</h2>
      </div>
      <div class="aside-card" v-if="pass.qr_code">
        <div class="aside-card__header">
          <span class="aside-card__id">Код на въезд</span>
        </div>
        <div class="aside-card__body">
          <div class="aside-card__item-qr_code">
            <img :src="pass.qr_code" />
            <button class="btn" type="button">Отсканировать QR код</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronUpIcon from "@/components/svg/ChevronUpIcon.vue";
import ChevronDownIcon from "@/components/svg/ChevronDownIcon.vue";

export default {
  name: "PassStatusInfo",
  components: { ChevronDownIcon, ChevronUpIcon },
  props: {
    pass: Object,
  },
  data() {
    return {
      isDesktop: window.innerWidth > 768,
      showStatus: window.innerWidth > 768 ? "DESKTOP" : "HIDDEN",
    };
  },
  methods: {
    changeShowStatus() {
      if (window.innerWidth <= 768) {
        if (this.showStatus === "MOBILE") {
          this.showStatus = "HIDDEN";
        } else {
          this.showStatus = "MOBILE";
        }
      } else {
        this.showStatus = "DESKTOP";
      }
    },
    getStatus(row) {
      let string = "status";
      switch (row.status.code) {
        case 1:
          break;
        case 6:
          string += " status--red";
          break;
        case 2:
          string += " status--orange";
          break;
        case 3:
        case 4:
        case 5:
          string += " status--green";
          break;
      }
      return string;
    },
  },
};
</script>
