<template>
  <div class="request-tab__item">
    <div class="request-tab__header" @click="changeShowStatus">
      <h2 class="request-tab__title">Документы на груз</h2>
      <ChevronUpIcon v-if="showStatus === 'MOBILE'" />
      <ChevronDownIcon v-if="showStatus === 'HIDDEN'" />
    </div>
    <FileUploadComponent v-if="showStatus !== 'HIDDEN'" :uploaded="files" disabled />
  </div>
</template>
<script>
import ChevronUpIcon from "@/components/svg/ChevronUpIcon";
import ChevronDownIcon from "@/components/svg/ChevronDownIcon";
import FileUploadComponent from "@/components/inputs/FileUploadComponent";

export default {
  name: "PassCargoDocuments",
  props: {
    pass: Object,
  },
  data() {
    return {
      showStatus: window.innerWidth > 768 ? "DESKTOP" : "HIDDEN",
    };
  },
  computed: {
    files() {
      return this.pass.requests.map((r) => r.cargoDocuments).flat();
    },
  },
  methods: {
    changeShowStatus() {
      if (window.innerWidth <= 768) {
        if (this.showStatus === "MOBILE") {
          this.showStatus = "HIDDEN";
        } else {
          this.showStatus = "MOBILE";
        }
      } else {
        this.showStatus = "DESKTOP";
      }
    },
  },
  components: { FileUploadComponent, ChevronDownIcon, ChevronUpIcon },
};
</script>
